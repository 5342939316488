define(['app'], function(app) {

  const productStockInformation = function() {
    const component = this;

    const _config = {
      selectors: {
        component: '[data-component=productStockInformation]',
        stock: '[data-product-stock-information]',
      },
      subscribeChannels: {
        stock: 'productStockInformation/newStockInformation',
      }
    };

    const _init = function(element, userDriven) {
      component.element = element;

      if (!userDriven) {
        app.subscribe(component.config.subscribeChannels.stock, component.getNewStock);
      }

      return component;
    };

    const _getNewStock = function (productId) {
      app.ajax.get({
        url: '/' + productId + '.stock',
        success: component.stockReceived,
        error: component.stockErrorHandler
      });
    };

    const _stockReceived = (response) => {
      let parent = component.element.parentNode;
      parent.innerHTML = response;
      let element = parent.querySelector('[data-component=productStockInformation]');
      if (element.querySelector('[data-product-stock-information]')) {
        component.init(element, true);
      }
    };

    const _stockErrorHandler = function() {
      console.error(
        'ERROR: Could not retrieve child product stock information');
    };

    const _checkSubscribeMessages = function(subscription, callback) {
      if (subscription.messages.length > 0) {
        let lastItem = subscription.messages.slice(-1)[0];
        callback(lastItem);
      }
    };

    component.init = _init;
    component.config = _config;
    component.stockReceived = _stockReceived;
    component.getNewStock = _getNewStock;
    component.stockErrorHandler = _stockErrorHandler;
    component.checkSubscribeMessages = _checkSubscribeMessages;

    return component;
  };

  return productStockInformation;
});
